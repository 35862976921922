import { useState } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_zx298nf", "template_cvr6571", e.target, "-olO2h8TUIA28OvWl")
      .then(
        (result) => {
          console.log("Message sent successfully:", result.text);
          clearState();

          Swal.fire({
            title: "Success!",
            text: "Your message has been sent.",
            icon: "success",
            confirmButtonText: "OK",
            timer: 3000,
            background: "#f9fafb",
            color: "#333",
            confirmButtonColor: "#1f2937",
            iconColor: "#10b981",
            customClass: {
              popup: "my-custom-popup rounded-[20px]",
              title: "my-custom-title",
              content: "my-custom-content",
              confirmButton: "my-custom-button rounded-[20px] px-12",
            },
          });
        },
        (error) => {
          console.log("Message sending failed:", error.text);

          Swal.fire({
            title: "Error!",
            text: "There was a problem sending your message.",
            icon: "error",
            confirmButtonText: "OK",
            timer: 3000,
            background: "#f9fafb",
            color: "#333",
            confirmButtonColor: "#1f2937",
            customClass: {
              popup: "my-custom-popup rounded-[20px]",
              title: "my-custom-title",
              content: "my-custom-content text-xl",
              confirmButton: "my-custom-button rounded-[20px] px-12",
            },
          });
        }
      );
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2 className="text-6xl font-extrabold">Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" onSubmit={handleSubmit} className="transition-transform">
                <div className="grid text-black grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div className="relative my-2 z-0 w-full">
                    <input
                      type="text"
                      id="name"
                      name="from_name"
                      className="block py-2.5 focus:pl-4 pl-32 w-full text-md text-black bg-blue-100 border-1 border-gray-300 rounded-[20px] transition-colors duration-300 focus:outline-none focus:bg-white focus:border-black peer"
                      placeholder=" "
                      required
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="name"
                      className="absolute text-md text-gray-600 duration-300 transform -translate-y-2 scale-75 top-3 left-4 bg-blue-100 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-full peer-focus:bg-white rounded-xl peer-focus:-translate-x-2"
                    >
                      Name
                    </label>
                  </div>
                  <div className="relative my-2 z-0 w-full">
                    <input
                      type="email"
                      id="email"
                      name="from_email"
                      className="block py-2.5 focus:pl-4 pl-32 w-full text-md text-black bg-blue-100 border-1 border-gray-300 rounded-[20px] transition-colors duration-300 focus:outline-none focus:bg-white focus:border-black peer"
                      placeholder=" "
                      required
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="email"
                      className="absolute text-md text-gray-600 duration-300 transform -translate-y-2 scale-75 top-3 left-4 bg-blue-100 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-full peer-focus:bg-white rounded-xl peer-focus:-translate-x-2"
                    >
                      Email
                    </label>
                  </div>
                </div>
                <div className="relative z-0 w-full mb-6">
                  <textarea
                    name="message"
                    id="message"
                    className="block py-2.5 focus:pl-4 pl-32 w-full text-md text-black bg-blue-100 border-1 border-gray-300 rounded-[20px] transition-colors duration-300 focus:outline-none focus:bg-white focus:border-black peer"
                    placeholder=" "
                    rows="4"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <label
                    htmlFor="message"
                    className="absolute text-md text-gray-600 duration-300 transform -translate-y-2 scale-75 top-3 left-4 bg-blue-100 px-2 peer-focus:px-2 peer-focus:text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-full rounded-xl peer-focus:bg-white peer-focus:-translate-x-2"
                  >
                    Message
                  </label>
                </div>
                <button type="submit" className="btn btn-custom px-6 py-4">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="p-4 text-4xl col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p className="text-3xl">
                <span className="text-4xl">
                  <i className="fa fa-map-marker"></i> Address
                </span>
                <p className="pl-16 text-3xl">{props.data ? props.data.address : "loading"}</p>
              </p>
            </div>
            <div className="contact-item">
              <p className="text-3xl">
                <span className="text-4xl">
                  <i className="fa fa-phone"></i> Phone
                </span>
                <p className="pl-16 text-3xl">{props.data ? props.data.phone : "loading"}</p>
              </p>
            </div>
            <div className="contact-item">
              <p className="text-3xl">
                <span className="text-4xl">
                  <i className="fa fa-envelope"></i> Email
                </span>
                <p className="pl-16 text-3xl">{props.data ? props.data.email : "loading"}</p>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              {/* <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
        <p>&copy; 2024 GreySky Robotics</p>

        </div>
      </div>
    </div>
  );
};
