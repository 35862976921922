import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const Features = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: "ease-in-out", // Easing for the animation
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <div id="features" className="text-center w-screen overflow-hidden py-0  flex justify-center items-center bg-gradient-to-b from-gray-100 to-gray-200 pt-28">
      <div className="  ">
        <div className="mb-12">
          <h2 className="text-6xl font-extrabold text-gray-800 mb-6">Features</h2>
          <p className="text-3xl text-gray-600">Explore the features that make us unique</p>
        </div>
        <div className="w-screen overflow-scroll">
        <div className="flex items-center pb-64 pt-24 px-[1.5rem] w-auto min-w-max gap-6 pr-32 overflow-x-scroll justify-center  lg:px-0 mx-0">   {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className=" w-[320px]
                  h-[300px] p-4"
                  data-aos="fade-up"
                  data-aos-delay={`${i * 100}`} // Delay for staggered animation effect
                >
                  <div className="p-6 bg-white rounded-[20px] shadow-lg  hover:shadow-2xl hover:scale-105 transform transition-transform transition-shadow duration-300">
                    <i className={`${d.icon} text-5xl text-indigo-600 mb-4`}></i>
                    <h3 className="text-3xl py-4 font-bold mb-2 text-gray-700">
                      {d.title}
                    </h3>
                    <p className="text-gray-600">{d.text}</p>
                  </div>
                </div>
              ))
            : "Loading..."}
        </div>
        </div>
      </div>
    </div>
  );
};
