import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

export const ProductDetails = ({ data, closeDetails }) => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out-cubic",
      once: true,
    });
    AOS.refresh();

    window.scrollTo(0, 0);
  }, []);

  const product = data;
  if (!product) return <div>Loading...</div>;

  return (
    <div className="h-auto  pb-24 w-screen top-0 absolute bg-gray-50  md:px-0">
      <div className="px-0 mx-0  relative pb-12">
        {/* Product Title and Description */}
        <div
  style={{
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${product.bgimg})`,
    backgroundBlendMode: "darken",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  }}
  className="text-center min-h-screen flex flex-col justify-center gap-10 md:gap-20 px-4"
>
          <h2
            className="text-7xl md:text-7xl  font-extrabold mb-8 text-white pb-12"
            data-aos="fade-up"
          >
            {product.title}
          </h2>
          <p
            className="text-gray-100 max-w-5xl  mx-auto text-5xl   leading-relaxed"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {product.text}
          </p>
        </div>

        {/* Product Details Sections */}
        <div className="flex mt-24 flex-col items-center space-y-16 md:space-y-24">
        <div className="mb-12">
          <h2 className="text-6xl font-extrabold text-gray-800 mb-6">Features</h2>
          <p className="text-3xl text-gray-600">Explore the features that make it unique</p>
        </div>
          {Object.entries(product.details).map(
            ([section, { features, img }], index) => (
              <div
                key={`feature-${index}`}
                className={`flex flex-col shadow-lg rounded-[30px] w-[80%]  ${
                  index % 2 === 0
                    ? "md:flex-row bg-white"
                    : "md:flex-row-reverse bg-gradient-to-r from-custom-blue-start to-custom-blue-end text-white"
                } items-center md:justify-between py-12 md:py-24 gap-12 md:gap-16 px-4 md:px-16 transition-transform duration-300 hover:scale-105`}
                data-aos="fade-up"
                data-aos-delay={`${200}`}
              >
                {/* Text Section */}
                <div
                  className={`flex flex-col  items-center md:items-start text-center md:text-left w-full md:w-1/2 space-y-6 md:space-y-8 ${
                    index % 2 === 0 ? "md:pr-16" : "md:pl-16"
                  }`}
                  data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
                  data-aos-delay={`${index * 300}`}
                >
                  <h3
                    className={`text-4xl md:text-5xl text-center lg:text-6xl font-extrabold ${
                      index % 2 === 0 ? "text-black" : "text-white"
                    }`}
                  >
                    {section}
                  </h3>
                  <ul
                    className={`text-lg md:text-xl lg:text-2xl ${
                      index % 2 === 0 ? "text-black/80" : "text-white/80"
                    } space-y-4`}
                  >
                    {features.map((feature, i) => (
                      <li
                        key={i}
                        className="relative text-3xl mx-12 pl-6 py-3 before:content-['•'] before:absolute before:left-0 before:text-red-500"
                      >
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Image Section */}
                <div
                  className="flex-shrink-0 w-full md:w-1/2 max-w-lg"
                  data-aos="fade-right"
                  data-aos-delay={`${600}`}
                >
                  <img
                    src={img}
                    alt={`${img} image`}
                    className="w-full h-auto rounded-lg transform transition duration-300 ease-in-out hover:scale-105"
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};
