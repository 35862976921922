import React, { useState } from "react";

const pricingData = [
  {
    plan: "Basic",
    detail:
      "Affordable entry point to our RFID membership solution. Ideal for businesses to test the solution before committing to a long-term plan.",
    hardware: "Greysky IoT Reader (USB Power) - 1",
    places: "1 location only",
    user: "Upto 10 users",
    adminAccess: "1 admin access",
    features: [
      "Free Dashboard Access",
      "Membership Management",
      "Customer Management",
      "Discount Management",
      "Loyalty Points",
      "Offers",
    ],
    customBranding: "No custom branding",
    customRoles: "No custom roles",
    supportOnboarding: "Support on Boarding",
    cost: "Free",
    cloudSubscription: "1 Month",
    subscriptionCost: "Free",
  },
  {
    plan: "Standard",
    detail:
      "Cost-effective solution for small businesses looking to leverage our easy-to-use, cloud-based dashboard for managing memberships.",
    hardware: "Greysky IoT Reader (USB Power) - 1",
    places: "1 location only",
    user: "Upto 250 users",
    adminAccess: "1 admin access",
    features: [
      "Free Dashboard Access",
      "Membership Management",
      "Customer Management",
      "Discount Management",
      "Loyalty Points",
      "Offers",
    ],
    customBranding: "No custom branding",
    customRoles: "No custom roles",
    supportOnboarding: "Support on Boarding",
    cost: "35,000 Rs",
    cloudSubscription: "6 Months",
    subscriptionCost: "₹10,000",
  },
  {
    plan: "Pro",
    detail:
      "Premium offering designed for growing businesses that need a scalable, remotely managed membership dashboard.",
    hardware: "Greysky IoT Reader (USB Power) - 2",
    places: "Upto 5 locations",
    user: "Unlimited users",
    adminAccess: "Upto 5 admin accesses",
    features: [
      "Free Dashboard Access",
      "Membership Management",
      "Customer Management",
      "Discount Management",
      "Loyalty Points",
      "Offers",
      "Personalized Concierge Service",
      "Offer Alerts and Notifications",
    ],
    customBranding: "Custom branding available",
    customRoles: "Limited custom roles",
    supportOnboarding: "Support on Boarding",
    cost: "95,000 Rs",
    cloudSubscription: "1 Year",
    subscriptionCost: "₹25,000",
  },
  {
    plan: "Enterprise",
    detail:
      "Top-tier solution tailored for large enterprises seeking a customized, cloud-based dashboard with advanced membership management features.",
    hardware: "Greysky IoT Reader Pro (Battery Powered) - 1",
    places: "Locations as per demand",
    user: "Unlimited users",
    adminAccess: "Unlimited admin accesses",
    features: [
      "Free Dashboard Access",
      "Membership Management",
      "Customer Management",
      "Discount Management",
      "Loyalty Points",
      "Offers",
      "Personalized Concierge Service",
      "Offer Alerts and Notifications",
      "Customized Features and Operations",
    ],
    customBranding: "Custom branding available",
    customRoles: "Custom roles on demand",
    supportOnboarding: "Support on Boarding",
    cost: "Get a quote",
    cloudSubscription: "Get a quote",
    subscriptionCost: "Get a quote",
  },
];

const Pricing = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const openDetails = (index) => {
    setSelectedPlan(index);
  };

  const closeDetails = () => {
    setSelectedPlan(null);
  };

  return (
    <div id="pricings" className="bg-gray-100 w-screen overflow-clip py-64">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-6xl font-extrabold text-black sm:text-7xl">
            Pricing Plans
          </h2>
          <p className="mt-4 pb-12 text-2xl text-gray-600">
            Choose a plan that suits your business needs.
          </p>
        </div>
        <div className="w-screen overflow-scroll">
          <div className="flex py-14 px-[1.5rem]  md:px-12 w-auto min-w-max gap-8 pr-32 overflow-x-scroll justify-center  p-8 lg:px-0 mx-auto">
            {pricingData.map((plan, index) => (
              <div
                key={index}
                className="border w-full max-w-[320px] transition-transform rounded-[20px] shadow-lg p-8 bg-white border-gray-300 hover:shadow-xl transform hover:scale-105 flex flex-col items-center justify-between"
              >
                {/* Plan Name */}
                <h3 className="text-4xl font-extrabold text-gray-900 mb-4">
                  {plan.plan}
                </h3>
                <p className="text-gray-600 text-center text-xl mb-6">
                  {plan.detail}
                </p>
                {/* Plan Price */}
                <p className="text-4xl font-extrabold text-blue-600 mb-6">
                  {plan.subscriptionCost}
                  <span className="text-lg font-medium text-gray-600">
                    {" "}
                    /year
                  </span>
                </p>

                {/* Plan Features */}
                <ul className="space-y-4 mb-8 text-center">
                  {[
                    { value: plan.hardware },
                    { value: plan.places },
                    { value: plan.user },
                    { value: plan.adminAccess },
                  ].map((item, index) => (
                    <li
                      key={index}
                      className="rounded-lg p-2 transition-all duration-300 hover:bg-blue-100"
                    >
                      <span className="text-md font-medium text-gray-700">
                        {item.value}
                      </span>
                    </li>
                  ))}
                </ul>

                {/* Button */}
                <div className="text-center w-full">
                  <button
                    className="flex h-12 w-full items-center justify-center px-6 py-3 rounded-full bg-gradient-to-r from-blue-500 to-blue-700 text-white shadow-md hover:shadow-lg transition-all duration-300"
                    onClick={() => openDetails(index)}
                  >
                    <span className="relative text-lg font-semibold">
                      View Details
                    </span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div class="flex text-2xl justify-center gap-10 items-center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://drive.google.com/file/d/1mk9bM9F3O1SrkqYqH-dkVCVp4BPO2WnE/view?usp=sharing"
            class="block"
          >
            <button class="flex items-center px-6 py-4  font-semibold text-white bg-gradient-to-r from-blue-500 to-indigo-600 rounded-full shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-indigo-300">
              <i class="fas fa-file-download mr-2"></i> Brochure
            </button>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://drive.google.com/file/d/1UIAKNZ5KC0sCFLdw-mwhzksElNCgeyM9/view?usp=sharing"
            class="block"
          >
            <button class="flex items-center px-6 py-4  font-semibold text-white bg-gradient-to-r from-green-500 to-teal-600 rounded-full shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-teal-300">
              <i class="fas fa-file-alt mr-2"></i> Pricing Plans
            </button>
          </a>
        </div>

        {/* Overlay Modal */}
        {selectedPlan !== null && (
          <div className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50">
            <div className="bg-white p-10 rounded-3xl shadow-2xl w-full max-w-3xl transform transition-transform duration-300 hover:scale-105">
              {/* Plan Name */}
              <h3 className="text-4xl font-extrabold text-blue-600 mb-6 text-center">
                {pricingData[selectedPlan].plan}
              </h3>

              {/* Plan Details */}
              <p className="text-gray-600 mt-4 mb-8 text-lg text-center">
                {pricingData[selectedPlan].detail}
              </p>

              {/* Plan Features List */}
              <ul className="text-md text-gray-800 space-y-5 mb-8">
                <li>
                  <strong className="font-semibold text-blue-500">
                    Hardware:
                  </strong>{" "}
                  {pricingData[selectedPlan].hardware}
                </li>
                <li>
                  <strong className="font-semibold text-blue-500">
                    Places:
                  </strong>{" "}
                  {pricingData[selectedPlan].places}
                </li>
                <li>
                  <strong className="font-semibold text-blue-500">
                    Users:
                  </strong>{" "}
                  {pricingData[selectedPlan].user}
                </li>
                <li>
                  <strong className="font-semibold text-blue-500">
                    Admin Access:
                  </strong>{" "}
                  {pricingData[selectedPlan].adminAccess}
                </li>
                <li>
                  <strong className="font-semibold text-blue-500">
                    Features:
                  </strong>{" "}
                  {pricingData[selectedPlan].features.join(", ")}
                </li>
                <li>
                  <strong className="font-semibold text-blue-500">
                    Custom Branding:
                  </strong>{" "}
                  {pricingData[selectedPlan].customBranding}
                </li>
                <li>
                  <strong className="font-semibold text-blue-500">
                    Custom Roles:
                  </strong>{" "}
                  {pricingData[selectedPlan].customRoles}
                </li>
                <li>
                  <strong className="font-semibold text-blue-500">
                    Support & Onboarding:
                  </strong>{" "}
                  {pricingData[selectedPlan].supportOnboarding}
                </li>
                <li>
                  <strong className="font-semibold text-blue-500">Cost:</strong>{" "}
                  {pricingData[selectedPlan].cost}
                </li>
                <li>
                  <strong className="font-semibold text-blue-500">
                    Cloud Subscription:
                  </strong>{" "}
                  {pricingData[selectedPlan].cloudSubscription}
                </li>
                <li>
                  <strong className="font-semibold text-blue-500">
                    Subscription Cost:
                  </strong>{" "}
                  {pricingData[selectedPlan].subscriptionCost}
                </li>
              </ul>

              {/* Close Button */}
              <div className="text-center">
                <button
                  className="bg-gradient-to-r from-blue-500 to-blue-700 text-white font-bold py-3 px-6 rounded-full shadow-lg hover:from-blue-600 hover:to-blue-800 hover:shadow-xl transition-all duration-300"
                  onClick={closeDetails}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pricing;
