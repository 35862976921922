import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Use Routes instead of Switch
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { Products } from "./components/products";
import { ProductDetails } from "./components/productDetails";  // Correct import statement
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Pricings from "./components/Pricings";
import { ParallaxProvider } from 'react-scroll-parallax';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  const hideScrollbarStyle = {
    overflowY: 'scroll', // Allow vertical scrolling
    scrollbarWidth: 'none', // Hide scrollbar for Firefox
    msOverflowStyle: 'none', // Hide scrollbar for Internet Explorer and Edge
  };

  const hideScrollbarStyleWebkit = {
    overflowY: 'scroll', // Allow vertical scrolling
    paddingRight: '15px', // Adjust according to scrollbar width
  };
  return (
    <ParallaxProvider>
    <Router>
      <div className="w-screen"  style={{
        ...hideScrollbarStyle,
        // For Webkit browsers
        WebkitOverflowScrolling: 'touch', // Smooth scrolling for iOS
      }}>
       <style>
            {`
              ::-webkit-scrollbar {
                width: 0px; /* Safari and Chrome */
              }
              ::-webkit-scrollbar-thumb {
                background: transparent;
              }
            `}
          </style>
        <Routes> {/* Replace Switch with Routes */}
          {/* Main landing page route */}
          <Route path="/" element={
            <>
              <Navigation />
              <Header data={landingPageData.Header} />
              <Features data={landingPageData.Features} />
              {/* <About data={landingPageData.About} /> */}
              <Services data={landingPageData.Services} />
              <Products data={landingPageData.Products} />
              {/* <Gallery data={landingPageData.Gallery} /> */}
              {/* <Testimonials data={landingPageData.Testimonials} />
              <Team data={landingPageData.Team} /> */}
              <Pricings/>
              <Contact data={landingPageData.Contact} />
            </>
          } />
        </Routes>
      </div>
    </Router>
    </ParallaxProvider>
  );
};

export default App;
