import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import { Parallax } from 'react-scroll-parallax'; // Parallax effect
import { ProductDetails } from "./productDetails";

export const Products = (props) => {
  // Initialize AOS animations
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-out-cubic", // Easing function
      once: true, // Only animate once
      offset: 100, // Offset for triggering earlier animations
    });
  }, []);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const openDetails = (index) => {
    setSelectedProduct(index); // Set selected product index to show details
  };

  const closeDetails = () => {
    setSelectedProduct(null); // Reset selected product when closing details
  };

  return (
    <div id="products" className="w-screen overflow-clip text-center py-16 pt-28 bg-gray-200">
      
      <div className="containe mx-auto px-8">
        <div className="mb-20">
          <h2
            className="text-6xl md:text-6xl font-extrabold mb-10 text-transparent bg-clip-text bg-black"
            data-aos="fade-up"
          >
            Products
          </h2>
          <p
            className="text-gray-700 max-w-4xl mx-auto text-3xl md:text-3xl mb-10"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Discover our range of products tailored to meet your needs. Browse
            and find what suits you best.
          </p>
        </div>
        <div className="flex  flex-col items-center">
          {props.data ? (
            props.data.map((d, i) => (
              <div 
                key={`${d.title}-${i}`}
                className={`flex flex-col bg-white md:w-[90%] md:p-12 p-4 py-8 rounded-[30px] shadow-lg 
                 ${
                  i % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                } items-center justify-between w-full mb-24 md:p-20 gap-16  mt-16 transition-transform duration-300 hover:scale-105`}
                data-aos="fade-up"
                data-aos-delay={`${i * 200}`} // Add delay for staggered animations
              >
                {/* Content Section */}
                <div
                  className={`flex flex-col md:items-start items-center text-left w-full md:w-1/2 ${
                    i % 2 === 0 ? "md:pr-16" : "md:pl-16"
                  }`}
                  data-aos={i % 2 === 0 ? "fade-right" : "fade-left"}
                  data-aos-delay={`${i * 300}`}
                >
                  <h3 className="text-5xl md:text-5xl font-extrabold mb-6 text-center text-transparent bg-clip-text bg-black">
                    {d.title}
                  </h3>
                  <p className="text-3xl text-center px-12 md:px-0 md:text-left md:text-3xl text-gray-700 mb-6">
                    {d.text}
                  </p>
                  <div className="mt-4">
                    <button
                      onClick={() => openDetails(i)}
                      className="px-8 py-4 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-full shadow-lg hover:shadow-xl hover:scale-105 transition-transform duration-300 ease-in-out"
                      data-aos="fade-right"
                      data-aos-delay={`${i * 600}`}
                    >
                      Learn More
                    </button>
                  </div>
                </div>

                {/* Parallax Image Section */}
                <Parallax speed={-4}>
                  <div
                    className="flex-shrink-0 mx-8 w-[90%] max-w-2xl mt-5 md:mt-0"
                    data-aos={i % 2 === 0 ? "fade-left" : "fade-right"}
                    data-aos-delay={`${i * 200}`}
                  >
                    <img
                      src={d.img}
                      alt={d.title}
                      className="w-full h-auto object-contain rounded-lg transform transition duration-300 ease-in-out hover:scale-110"
                    />
                  </div>
                </Parallax>
              </div>
            ))
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
      {selectedProduct !== null && (
        <div className="fixed overflow-y-scroll inset-0 w-screen bg-black bg-opacity-40 flex justify-center items-center z-50">
          <button
            onClick={() => closeDetails()}
            className="fixed top-4 right-4 px-4 py-2 bg-gradient-to-r from-red-500 to-pink-500 text-white font-semibold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out text-sm md:text-base z-50"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708.708L2.707 7.5H14.5a.5.5 0 0 1 .5.5z"
              />
            </svg>
          </button>

          <ProductDetails
            data={props.data[selectedProduct]} // Pass only selected product data
            closeDetails={closeDetails} // Function to close details
          />
        </div>
      )}
    </div>
  );
};
