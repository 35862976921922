import React, { useState } from "react";
import logo from "../logo.svg";

export const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className=" fixed  top-0 z-50 flex flex-wrap sm:justify-start sm:flex-nowrap w-full bg-gray-800 text-sm dark:bg-white/40 backdrop-blur-md">
      <nav className="max-w-[85rem] w-full px-6 mx-auto  sm:flex sm:items-center sm:justify-between">
        {/* Brand */}
        <div
          className={`flex  md:py-0 py-3 px-4 ${
            isMenuOpen ? "border-b-2 shadow-sm" : ""
          } transition-transform items-center justify-between`}
        >
          <a
            href="#page-top"
            className="text-center p-3 items-center w-auto text-5xl font-light text-gray-900"
          >
            <img className="h-8 md:h-10" src={logo} alt="Logo" />{" "}
          </a>{" "}
          <div className="sm:hidden">
            {/* Toggle Button for Mobile */}
            <button
              type="button"
              className="hs-collapse-toggle relative size-7 flex justify-center items-center gap-2 rounded-lg "
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation"
            >
              {/* Menu Icons */}
              {!isMenuOpen ? (
               <svg width="800px" height="800px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g id="Menu / Menu_Alt_01">
               <path id="Vector" d="M12 17H19M5 12H19M5 7H19" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
               </g>
               </svg>
              ) : (
                <svg fill="#000000" width="800px" height="800px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 14.545L1.455 16 8 9.455 14.545 16 16 14.545 9.455 8 16 1.455 14.545 0 8 6.545 1.455 0 0 1.455 6.545 8z" fill-rule="evenodd"/>
            </svg>
              )}
              <span className="sr-only">Toggle</span>
            </button>
          </div>
        </div>

        {/* Navigation Menu */}
        <div
          className={`hs-collapse py-8 overflow-hidden transition-all duration-300 basis-full grow sm:block ${
            isMenuOpen ? "block" : "hidden"
          }`}
          id="hs-navbar-dark"
        >
          <div className="flex flex-col gap-5 mt-5  sm:flex-row sm:items-center sm:justify-end sm:mt-0 sm:ps-5">
            <a
              className="relative text-black/60 hover:text-blue-700 transition-all duration-300 ease-in-out font-medium text-2xl md:text-3xl py-2.5 ml-6 before:absolute before:-bottom-1 before:left-0 before:w-0 before:h-[2px] before:bg-blue-700 before:transition-all before:duration-300 before:ease-in-out hover:before:w-full"
              href="#services"
            >
              SERVICES
            </a>
            <a
              className="relative text-black/60 hover:text-blue-700 transition-all duration-300 ease-in-out font-medium text-2xl md:text-3xl py-2.5 ml-6 before:absolute before:-bottom-1 before:left-0 before:w-0 before:h-[2px] before:bg-blue-700 before:transition-all before:duration-300 before:ease-in-out hover:before:w-full"
              href="#products"
            >
              PRODUCTS
            </a>
            <a
              className="relative text-black/60 hover:text-blue-700 transition-all duration-300 ease-in-out font-medium text-2xl md:text-3xl py-2.5 ml-6 before:absolute before:-bottom-1 before:left-0 before:w-0 before:h-[2px] before:bg-blue-700 before:transition-all before:duration-300 before:ease-in-out hover:before:w-full"
              href="#contact"
            >
              CONTACT
            </a>
            <a
              className="relative text-black/60 hover:text-blue-700 transition-all duration-300 ease-in-out font-medium text-2xl md:text-3xl py-2.5 ml-6 before:absolute before:-bottom-1 before:left-0 before:w-0 before:h-[2px] before:bg-blue-700 before:transition-all before:duration-300 before:ease-in-out hover:before:w-full"
              href="#pricings"
            >
              PRICINGS
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
};
