import React from "react";
import image from "../intro-bg.jpg"; // Import your image

export const Header = (props) => {
  return (
    <header
      id="header"
      className="relative w-full h-screen bg-cover bg-center flex items-center justify-center"
      style={{ backgroundImage: `url(${image})` }} // Apply the imported image
    >
      <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
        <div className="text-center text-white px-6 md:px-12">
          <h1 className="text-8xl md:text-9xl font-bold mb-4">
            {props.data ? props.data.title : "Loading"}
          </h1>
          <p className="text-3xl py-24 md:text-5xl md:px-64 font-thin smb-6">
            {props.data ? props.data.paragraph : "Loading"}
          </p>
          <a
            className="px-8 py-4 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-full shadow-lg hover:shadow-xl hover:scale-105 transition-transform duration-300 ease-in-out"
            href="#features"
          >
            Learn More
          </a>
        </div>
      </div>
    </header>
  );
};
