import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const Services = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: "ease-in-out", // Easing for the animation
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <div id="services" className=" w-screen text-center py-16 bg-gray-50">
      <div className=" mx-auto">
        <div className="mb-12">
          <h2 className="text-6xl font-extrabold text-gray-800 mb-4">Our Services</h2>
          <p className="text-3xl text-gray-600 max-w-2xl mx-auto">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed dapibus leo nec.
          </p>
        </div>
        <div className="w-screen overflow-scroll">
        <div className="flex items-center justify-center pb-64 pt-24 px-[1.5rem] w-auto min-w-max gap-8 pr-32 overflow-x-scroll  lg:px-0 mx-0">
            {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  className=" w-[320px] p-4 py-16"
                  data-aos="fade-up"
                  data-aos-delay={`${i * 100}`} // Staggered animation delay
                >
                  <div className="p-6  shadow-lg bg-white/5 hover:bg-white/10  hover:shadow-2xl rounded-[20px] transform hover:-translate-y-2 transition-transform duration-300">
                    <i className={`${d.icon} text-5xl text-indigo-600 mb-4`}></i>
                    <h3 className="text-3xl font-bold py-4 text-gray-700 mb-2">
                      {d.name}
                    </h3>
                    <p className="text-gray-600 py-4">{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        </div>
      </div>
    </div>
  );
};
